import { Apis } from '../../config';
import api from '../../ApiConfig';
import { parseCookies } from 'nookies'
import { toast } from 'react-toastify';
import { data } from 'jquery';
const cookies = parseCookies()
const getRegister = async (data) =>{
    try {
        let result = await api.post(Apis.GetRegister, data);
        return result.data;
    } catch (error) {
        throw Error('Unable to register')
    }
}

const getSignUpNextStep = async (data) =>{
    try {
        let result = await api.post(Apis.GetSignUpNextStep, data);
        return result.data;
    } catch (error) {
        throw Error('Unable to post data')
    }
}



const getLogin = async (data) =>{
    try {
        let result = await api.post(Apis.GetLogin, data);
        return result.data;
    } catch (error) {
        return error;
        // toast.error('Sorry! Invalid credentials', {
        //     position: "top-center",
        //     autoClose: 1000,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        //     });
      //  throw Error('Unable to Login')
    }
}
const getVerifyOtp = async (data, otp) =>{
    try {
         const user_id = data.user.id;
         const user_type = data.user.user_type;
          let result = await api.post(Apis.GetVerifyOtp + user_id + '/' + user_type + '/' + otp);
          return result.data;
    } catch (error) {
        throw Error('Unable to verify otp')
    }
}
const getVerifyOtpForgetPassword = async (data, otp) =>{
    try {
         const user_id = data.user_id;
         const user_type = data.user_type;
          let result = await api.post(Apis.GetVerifyOtp + user_id + '/' + user_type + '/' + otp);
          return result.data;
    } catch (error) {
        throw Error('Unable to verify otp')
    }
}


const getUser = async () =>{
    try {
   
        const user = JSON.parse(cookies.user);
        let result = await api.get(Apis.GetUser + user.authId + '/' + user.auth_type);
          return result.data;
    } catch (error) {
        throw Error('Unable to get user')
    }
}

const updateUserPassword = async (password) =>{
    try {
        const user = JSON.parse(cookies.user);
        const data = {
            user_type:user.auth_type,
            password:password
        }
       
        let result = await api.post(Apis.UpdatePassword + user.authId, data);
          return result.data;
    } catch (error) {
        throw Error('Unable to get user')
    }
}
const updateUserProfile = async (data) =>{
    try {
        const user = JSON.parse(cookies.user);
         const updateData = {
            name:data.name,
            phone:data.phone,
            email:data.email,
            user_type:user.auth_type
         }
        let result = await api.post(Apis.UpdateProfile + user.authId, updateData);
          return result.data;
    } catch (error) {
        throw Error('Unable to update user')
    }
}

const forgetPassword = async (data) =>{
    try {
        let result = await api.post(Apis.ForgetPassword, data);
          return result.data;
    } catch (error) {
        throw Error('Unable to get user')
    }
}

const sendOTP = async (email) =>{
    try {
        const user = JSON.parse(cookies.user);
        const data = {
          type:user.auth_type,
          email:email
        }
        let result = await api.post(Apis.SendOTP,data);
          return result.data;
    } catch (error) {
        throw Error('Unable to send otp')
    }
}
const resendOTP = async (user) =>{
    try {
        const data = {
          type:user.type,
          email:user.email
        }
        let result = await api.post(Apis.ResendOTP,data);
          return result.data;
    } catch (error) {
        throw Error('Unable to resend otp')
    }
}

const resetPassword = async (data) =>{
    try {
        let result = await api.post(Apis.ResetPassword, data);
          return result.data;
    } catch (error) {
        throw Error('Unable to reset password')
    }
}



const exportedObject = {
    getRegister,
    getSignUpNextStep,
    getLogin,
    getVerifyOtp,
    getUser,
    updateUserPassword,
    forgetPassword,
    sendOTP,
    resendOTP,
    updateUserProfile,
    getVerifyOtpForgetPassword,
    resetPassword

};
export default exportedObject;