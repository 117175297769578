import React from 'react'
import { GetAuth } from '../../../helpers';
import { toast } from 'react-toastify';
const ResendOpt = ({submitData, setResendOtp}) => {
 
 const resentOpt = async () =>{
    setResendOtp(false);
    const data = {
        type:submitData.user.user_type,
        email:submitData.user.phone
    }
    const res = await GetAuth.resendOTP(data);
    if(res && res.status === 200){
        if(res.message === 'Otp sent successfully!'){
            toast.success(res.message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
        }else{
            toast.error(res.message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
        }
      
           
    }
 }
  return (
    <>
     <button type='button' className='resentBtn' onClick={resentOpt}>Resend Otp</button>
    </>
  )
}

export default ResendOpt