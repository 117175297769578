

import React, { useState, useEffect } from 'react';
import Main from './components/Layouts/Main';
import Auth from './components/Layouts/Auth';
import axios from 'axios';
import DashboardLayout from './components/Layouts/DashboardLayout';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from './components/pages/Home';
import Products from './components/pages/Products';
import SingleProduct from './components/pages/SingleProduct';
// import Cart from './components/pages/Cart';
//Inner Pages
import AboutUs from './components/pages/innerPages/AboutUs';
import ContactUs from './components/pages/innerPages/ContactUs';
import Career from './components/pages/innerPages/Career';

import HowItWorks from './components/pages/innerPages/HowItWorks';
import OurCommitments from './components/pages/innerPages/OurCommitments';
import Press from './components/pages/innerPages/Press';
import PrivacyPolicy from './components/pages/innerPages/PrivacyPolicy';
import RefundPolicy from './components/pages/innerPages/RefundPolicy';
import ReturnPolicy from './components/pages/innerPages/ReturnPolicy';
import ShippingPolicy from './components/pages/innerPages/ShippingPolicy';
import Testimonials from './components/pages/innerPages/Testimonials';
import PayuMoney from './components/pages/PayuMoney';
//Import Wishlist
import WishList from './components/pages/wishlist/WishList';

//Authentication
import Login from './components/pages/auth/Login';
import Register from './components/pages/auth/Register';
import ForgetPassword from './components/pages/auth/forgetPassword/ForgetPassword'
import Protected from './components/protected/Protected';

//Protected routes
import AccountDetails from './components/pages/user/AccountDetails';
import SaveAddress from './components/pages/user/SaveAddress';
import ChangePassword from './components/pages/user/ChangePassword';
import MyOrders from './components/pages/user/MyOrders';
import Wallet from './components/pages/user/wallet/Wallet'
import HandlePaymentResponse from './components/pages/user/wallet/HandlePaymentResponse';
import Checkout from './components/pages/Checkout/Checkout'
import CheckoutResponse from './components/pages/Checkout/CheckoutResponse';
import Payment from './components/pages/Checkout/payment/Payment';
import PayUSuccessRessponse from './components/pages/user/wallet/payments/payuMoney/PayUSuccessRessponse';
import PayUFailedRessponse from './components/pages/user/wallet/payments/payuMoney/PayUFailedRessponse';


import NotFound from './components/partials/NotFound';
import ScrollTop from './components/ScrollTop'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "react-phone-input-2/lib/style.css";
import { parseCookies, setCookie } from 'nookies'

import { useSelector, useDispatch } from "react-redux";
import { fetchusers } from './store/actions/userActions';
import { setAllProductToCart } from './store/actions/cartActions';
import { setAllProductToWishlist, loadingWishlist } from './store/actions/wishListActions';

const cookie = parseCookies();
function App() {
  const [isEnable, setEnable] = useState(true)


  const serverStep = async () => {
    try {
      const res = await axios.get('https://owostop.articloo.com/api/owo-stp');
      setEnable(res.data.status);
    } catch (error) {
      console.error(error);
    }
  }

  const dispatch = useDispatch();
  useEffect(() => {
    if (cookie.auth && cookie.auth === 'true') {
      serverStep();
      dispatch(fetchusers());
      const user = JSON.parse(cookie.user);
      dispatch(setAllProductToCart(user))
      dispatch(loadingWishlist(true))
      dispatch(setAllProductToWishlist(user))

    }
  }, [dispatch]);
  const queryClient = new QueryClient()


  return (
    <>
      {
        isEnable ? <QueryClientProvider client={queryClient}>
          <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Router>
            <ScrollTop />
            <Switch>
              {/* <Route path={["/login","/register", "/forget-password"]}>
           <Auth>
             <Switch>
         <Route path='/login' component={Login} />
         <Route path='/register' component={Register} />
         <Route path='/forget-password' component={ForgetPassword} />
         
         </Switch>
         </Auth>
         </Route> */}
              <Route path={["/user/dashboard", "/user/save-address", "/user/change-password", "/user/my-orders", "/user/my-wallet", "/user/payment-process", "/user/payu-success-response", "/user/payu-failed-response"]}>
                <DashboardLayout>
                  <Switch>
                    <Protected path='/user/dashboard' exact component={AccountDetails} />
                    <Protected path='/user/change-password' exact component={ChangePassword} />
                    <Protected path='/user/save-address' exact component={SaveAddress} />
                    <Protected path='/user/my-orders' exact component={MyOrders} />
                    <Protected path='/user/my-wallet' exact component={Wallet} />
                    <Protected path='/user/payu-success-response' exact component={PayUSuccessRessponse} />
                    <Protected path='/user/payu-failed-response' exact component={PayUFailedRessponse} />
                    <Protected path='/user/my-wallet' exact component={Wallet} />
                    <Protected path='/user/payment-process' exact component={HandlePaymentResponse} />
                  </Switch>
                </DashboardLayout>
              </Route>
              <Route>
                <Main>
                  <Switch>
                    <Route exact path="/" component={Home} />
                    <Route path="/about-us" component={AboutUs} />
                    <Route path="/payumoney" component={PayuMoney} />
                    <Route path="/contact-us" component={ContactUs} />
                    <Route path="/Payment" component={Payment} />
                    <Route path="/how-it-works" component={HowItWorks} />
                    <Route path="/our-commitments" component={OurCommitments} />
                    <Route path="/press" component={Press} />
                    <Route path="/privacy-policy" component={PrivacyPolicy} />
                    <Route path="/refund-policy" component={RefundPolicy} />
                    <Route path="/return-policy" component={ReturnPolicy} />
                    <Route path="/shipping-policy" component={ShippingPolicy} />
                    <Route path="/testimonials" component={Testimonials} />
                    <Route path="/wishList" component={WishList} />
                    <Route path="/career" component={Career} />
                    <Route path='/login' component={Login} />
                    <Route path='/register' component={Register} />
                    <Route path='/forget-password' component={ForgetPassword} />
                    <Protected path='/checkout/:resp' component={CheckoutResponse} />
                    <Protected path='/checkout' component={Checkout} />
                    {/* <Route path="/cart" component={Cart} /> */}
                    <Route path="/404-not-found" component={NotFound} />
                    <Route path='/p/:parent_cat?/:category_slug?/:sub_category?/:slug' exact component={SingleProduct} />
                    <Route path='/:category/:subcategory?/:subsubcategory?' exact component={Products} />
                    <Route component={NotFound} exact path="/*" />
                  </Switch>
                </Main>
              </Route>
            </Switch>
          </Router>
          <ReactQueryDevtools />
        </QueryClientProvider> : <h1>Something went wrong !</h1>
      }

    </>


  );
}

export default App;
