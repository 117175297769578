import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import { Link } from "react-router-dom";
import { Formik, Field } from "formik";
import { GetAuth } from "../../../../helpers";
import OtpInput from "react-otp-input";
import ResetPassword from "./ResetPassword";
import { toast } from "react-toastify";
import ResendOpt from "../ResendOpt";
import ResendOtpForgetPassword from "../ResendOtpForgetPassword";
import CountdownTimer from "../../../partials/CountdownTimer";
import "react-phone-input-2/lib/style.css";



const ForgetPassword = () => {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isSendOtp, setSendOtp] = useState(false);
  const [numInputs, setnumInputs] = useState(6);
  const [otp, setOtp] = useState("");
  const [otpErrorResp, setOtpErrorResp] = useState(null);
  const [isDisabled, setDisabled] = useState(false);
  const [email, setEmail] = useState("");
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [userDetails, setUserDeatails] = useState(null);
  const [isResendOtp, setResendOtp] = useState(false);



  const handleSubmitForget = async (values) => {
    const data = {
      type: values.type,
      phone: values.phone,
    };

    const res = await GetAuth.forgetPassword(data);

    if (res && res.data) {
      setSendOtp(true);
      setUserDeatails(res.data);
      
    } else {
      toast.error(res.message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const verifyOtp = async (otp) => {
    setLoading(true);
    const data = {
      user_id: userDetails.id,
      user_type: userDetails.user_type,
    };
    const res = await GetAuth.getVerifyOtpForgetPassword(data, otp);
    if (res.status === 401) {
      setOtpErrorResp(res.message);
      setLoading(false);
    }
    if (res.status === 200) {
      setIsResetPassword(true);
      setLoading(false);
    }
  };

  const clearOtp = () => {
    setOtp("");
  };
  const handleOtp = async (opt) => {
    setOtpErrorResp("");
    setOtp(opt);
  };
  const getOtp = async () => {
    verifyOtp(otp);
  };
  const resetPassword = async (data) => {
    const res = await GetAuth.resetPassword(data);
    if (res && res.status === 200) {
      toast.success(res.message, {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setTimeout(function () {
        window.location.href = "/"; //will redirect to your blog page (an ex: blog.html)
      }, 2000); //will call the function after 2 secs.
    }
  };

  


  return (
    <section className="login-section">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-4" />
          <div className="col-lg-4">
            <div className="loginbox shadow">
              <img
                src="images/owo-blue-logo.png"
                className="loginlogo d-block mx-auto"
                alt=""
              />
              <h4>
                {isResetPassword && isSendOtp
                  ? "Reset Password"
                  : "Forget Password"}
              </h4>
              {isResetPassword && isSendOtp ? (
                <>
                  <ResetPassword
                    userDetails={userDetails}
                    resetPassword={resetPassword}
                  />
                </>
              ) : isSendOtp ? (
                <div className="otp-box">
                  <p>
                    I have sent OTP <br /> {email}
                  </p>
                  <OtpInput
                    inputStyle="inputStyle"
                    errorStyle="error"
                    shouldAutoFocus
                    value={otp}
                    onChange={handleOtp}
                    numInputs={numInputs}
                    isDisabled={isDisabled}
                    isInputNum={true}
                    separator={<span>-</span>}
                  />
                  <div className="btn-row">
                    <button
                      className="btn clear-btn margin-top--large"
                      type="button"
                      disabled={isDisabled || otp.trim() === ""}
                      onClick={clearOtp}
                    >
                      Clear
                    </button>
                    <button
                      className="btn sb-btn margin-top--large"
                      disabled={otp.length < numInputs}
                      onClick={getOtp}
                    >
                      Verify
                    </button>
                  </div>
                  {
                    !isResendOtp ? <CountdownTimer setResendOtp={setResendOtp}/> :  userDetails !== null && (
                      <ResendOtpForgetPassword type={userDetails.user_type} phone={userDetails.phone} setResendOtp={setResendOtp}/>
                   )
                  }
                  
                    
                  {isLoading && (
                    <div className="loading-container mh50">
                      <img
                        src={process.env.PUBLIC_URL + "/images/loader.gif"}
                        alt="loading icon"
                      />{" "}
                      Please wait..
                    </div>
                  )}
                  {otpErrorResp}
                </div>
              ) : (
                <Formik
                  enableReinitialize={true}
                  initialValues={{ phone: "", type: "" }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.type) {
                      errors.type = "Please select user type.";
                    }

                    if (!values.phone || Object.keys(values.phone).length <= 2) {
                      errors.phone = "Phone is required.";
                    } else {
                    }
                    if (Object.keys(values.phone).length < 12) {
                      setEmail("");
                      errors.phone = "Invalid Phone Number.";
                    } else {
                      setEmail(values.phone);
                    }
                    return errors;
                  }}
                  onSubmit={handleSubmitForget}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="form-group type">
                        <div role="group" aria-labelledby="my-radio-group">
                          <label className="mr-3 mb-0">
                            <Field
                              type="radio"
                              name="type"
                              value="individual"
                              className="mr-1"
                            />
                            Individuals
                          </label>
                          <label className="mb-0">
                            <Field
                              type="radio"
                              name="type"
                              value="corporate"
                              className="mr-1"
                            />
                            Corporate
                          </label>
                        </div>
                        <span className="help-error">
                          {errors.type && touched.type && errors.type}
                        </span>
                      </div>
                      <div className="form-group">
                        <Field name="phone">
                          {({ field, form }) => (
                            <PhoneInput
                              country={"in"}
                              onlyCountries={["in"]}
                              countryCodeEditable={false}
                              {...field}
                              onChange={(value) =>
                                form.setFieldValue("phone", value)
                              }
                            />
                          )}
                        </Field>

                        {/* <input type="text"
                                            placeholder="Enter your mobile number"
                                            className="form-control"
                                            name="phone"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.phone}
                                        /> */}
                        <span className="help-error">
                          {errors.phone && touched.phone && errors.phone}
                        </span>
                      </div>

                      <div className="form-group">
                        <button
                          type="submit"
                          className="btn btn-green btn-block"
                        >
                          Send OTP
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgetPassword;
