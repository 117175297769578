import React, { useState, useEffect } from 'react'
import { IMAGE_URL } from '../../config'
import { Link, useHistory } from 'react-router-dom'
import { parseCookies } from 'nookies'
import { useSelector, useDispatch } from "react-redux";
import { addToCart } from '../../store/actions/cartActions';
const cookie = parseCookies()
const SuggestionItem = (props) => {
    let history = useHistory();
    const dispatch = useDispatch();
    const userDetails = useSelector(state => state.user);
    const cartItems = useSelector(state => state.cart.cartItems);
    const [isLoading, setLoading] = useState(true);
    const item = props.data;
    const [pPrice, setPPrice] = useState(null);
    const [mPrice, setMPrice] = useState(null);
    const [fVName, setfVName] = useState(null);
    const [fVar, setFvar] = useState(null);

    const getData = () => {
        const fVariant = item?.variant && item?.variant[0];
        var purchagePrice = fVariant ? fVariant.variant_discount !== null ? fVariant.variant_price - fVariant.variant_discount : fVariant.variant_price : item?.purchase_price
        var mainPrice = fVariant ? fVariant.variant_price : item?.mrp;
        var fVarientName = fVariant ? fVariant.variant_name : null
        setPPrice(purchagePrice);
        setMPrice(mainPrice);
        setfVName(fVarientName);
        setFvar(fVariant);
        setLoading(false);
    }
    useEffect(() => {
        getData();
    }, [])
    const handleCartSubmit = () => {
        if (cookie.auth && cookie.auth === 'true') {
          if (userDetails && userDetails.user !== null) {
            const data = {
              "user_id": userDetails.user.id,
              "user_type": userDetails.user.user_type,
              "product_id": item?.id,
              "variant_id": fVar ? fVar?.id : null,
              "quantity": 1
            }
            
            const wishlist_id = item?.id;
            console.log(data);
            //props.addCart(data, wishlist_id); 
            
            dispatch(addToCart(data, wishlist_id)).then(
              getData()
            
            );
            props.resetSearch();
          }

        } else {
    
          return history.push({
              pathname: "/login",
              state: {
                  pre_path: window.location.pathname
              }
          });
        }
      }
    const getItemInfo = (item) => {
        if (item.variant_discount !== null) {
            const discPer = (item.variant_discount / item.variant_price) * 100;
            const disc_price = item.variant_price - item.variant_discount;
            return " Rs." + disc_price;
        } else {
            return null;
        }
    }
    const handleChangeSize = (val) => {
        const FilteredVariant = item.variant.find(el => parseInt(el.id) === parseInt(val));
        var purchagePrice = FilteredVariant ? FilteredVariant.variant_discount !== null ? FilteredVariant.variant_price - FilteredVariant.variant_discount : FilteredVariant.variant_price : item?.product?.purchase_price;
        var mainPrice = FilteredVariant ? FilteredVariant.variant_price : item?.product?.mrp;
        setFvar(FilteredVariant);
        setPPrice(purchagePrice);
        setMPrice(mainPrice);
        setfVName(FilteredVariant.variant_name);
    }
    const isCart = cartItems?.find(el => parseInt(el.product_id) === item?.id);
    let productUrl  = '/';
    if(item.category !== null && item.category.sub_category !== null && item.subsubcate !== null){
         productUrl = `/p/${item.category.category_slug}/${item.category.sub_category.subcategory_slug}/${item.subsubcate.subsubcategory_slug}/${item.product_slug}`;
    }else if(item.category !== null && item.category.sub_category !== null){
        productUrl = `/p/${item.category.category_slug}/${item.category.sub_category.subcategory_slug}/${item.product_slug}`;
    }else{
        productUrl = `/p/${item.category.category_slug}/${item.product_slug}`;
    }
     
    return (
        <tr >
            <td className="cpit" style={{ width: '9%' }}>
                <img src={IMAGE_URL + item.thumbnail_img} className="img-responsive" alt='' />
            </td>
            <td className="cpft" style={{ width: '44%' }}>
                <h5 className="text-bold">
                
                      
                        <a href={productUrl}>
                        {item.product_name}
                    </a>
                </h5>
                <p className="text-green">
                    {item?.brand_name}
                </p>
            </td>
            <td className="cpl" >
                {
                    parseInt(item.has_variant) === 1 &&
                    <div className="dropdown ">

                        <button className="btn dropdown-toggle qty-dropdown" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {fVName} -   Rs. {pPrice}
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            {
                                item.variant?.map((item2, i) => {
                                    return <span className="dropdown-item" key={i} onClick={() => handleChangeSize(item2.id)}>{item2.variant_name} - <span> {getItemInfo.call(this, item2)}</span></span>
                                })
                            }
                        </div>
                    </div>
                }
            </td>
            {/* <td className="cpq" style={{ width: '18%' }}>
                <div className="form-group form-inline">
                    <label className="col-sm-2 col-form-label" htmlFor="name">
                        <p>Qty</p>
                    </label>
                    <input type="number" className="form-control custom-select pqty_1 pqtyt" defaultValue={1} />
                </div>
            </td> */}
            <td className="cpp" style={{ width: '15%' }}>
            <p className="listprice ">
           Rs {pPrice}
           {/* <span className="acci1 text-color1">
             <del>Rs {mPrice}</del>
           </span> */}
         </p>
            </td>
        
                <td style={{ width: '14%' }} className="cpa">
                    {
                         !isCart &&  <button type="button" className="qtybtn cpab" style={{ fontSize: '10px' }} onClick={handleCartSubmit}>Add +</button>
                    }
               
            </td>
            
           
        </tr>
    )
}

export default SuggestionItem