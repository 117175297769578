import React,{useState} from 'react'
import { Link } from 'react-router-dom'
import { parseCookies, setCookie } from 'nookies'
import { Formik, Field } from 'formik';
import { GetAuth } from '../../../helpers'
import OtpVerification from './OtpVerification';
import RegisterNextStep from './RegisterNextStep';
import { useDispatch } from "react-redux";
import { toast } from 'react-toastify';
const cookie = parseCookies();

const Login = (props) => {
 
const [isServerError, setServerError] = useState(false);
const [isNotVarified, setNotVerified] = useState(false);
const [userDetails, setUserDetails] = useState(null);
const [otpErrorResp, setOtpErrorResp] = useState(null);
const [isLoading, setLoading] = useState(false);
const [isLoginLoader, isSetLoginLoader] = useState(false);

const [isNotStepTwo, setIsNotStepTwo] = useState(false);
  if (cookie.auth && cookie.auth === 'true') {
    props.history.push('/');
  }

  const submitSignIn = async (values) => {
    isSetLoginLoader(true);
    const res = await GetAuth.getLogin(values);

     // console.log(res);

     if(res.data){
       const result = res.data;
       setUserDetails(res.data);
       if(result.user.user_type === "individual"){
        if(result.user.verified !== '0'){
          persistUserDetails(res.data);
         }else{
          setNotVerified(true);
          resendOtpFn(result.user.user_type, result.user.phone);
         }
       }else{
         if(result.user.step_2 === '0'){
          setIsNotStepTwo(true)
         }else{
          if(result.user.verified === '0'){
            setNotVerified(true);
            resendOtpFn(result.user.user_type, result.user.phone);
           }else{
            persistUserDetails(res.data);
           }
              
         }
            
       }
     }else{
      
       setServerError(true);
       isSetLoginLoader(false);
     }
   
  }
  const resendOtpFn = async (user_type, phone) =>{
    const data = {
        type:user_type,
        email:phone
    }
    const res = await GetAuth.resendOTP(data);
    if(res && res.status === 200){
        if(res.message === 'Otp sent successfully!'){
            toast.success(res.message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
        }else{
            toast.error(res.message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
        }
   }
  }


 const verifyOtp = async (otp) =>{
  setLoading(true)
    const res = await GetAuth.getVerifyOtp(userDetails, otp);
    if(res.status === 401){
        setOtpErrorResp(res.message);
        setLoading(false)
    }
    if(res.status === 200){
      persistUserDetails(userDetails);
    }
 }


 const signupNextStep = async (data) =>{
  const postData = {
      user_id:userDetails.user.id,
      user_type:userDetails.user.user_type,
      legal_name:data.legal_name,
      gst:data.gst,
      state:data.state,
      pan:data.pan
  }
  const res = await GetAuth.getSignUpNextStep(postData);
  if(res.status === 200){
    setIsNotStepTwo(false);
    setNotVerified(true);
  }

}


  const persistUserDetails = (data) => {
    isSetLoginLoader(false);
    const user = {
        authId: data.user.id,
        auth_type:data.user.user_type
    }
       
    setCookie(null, 'auth', 'true', {
        sameSite:true,
        maxAge: 30 * 24 * 60 * 60,
        path: '/',
      })

    setCookie(null, 'auth_access', data.access_token, {
        sameSite:true,
        maxAge: 30 * 24 * 60 * 60,
        path: '/',
      })

    setCookie(null, 'user', JSON.stringify(user), {
        sameSite:true,
        maxAge: 30 * 24 * 60 * 60,
        path: '/',
      })
      
      console.log(props.location.state);
      let from = '/';
            if (props.location.state) {
                from = props.location.state.pre_path;
            }
     
      setTimeout(function () {
        window.location.href = from; //will redirect to your home
    }, 1000); 

    };
  return (

    <section className="login-section">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-4" />
          <div className="col-lg-4">
            <div className="loginbox shadow">
              <img src="images/owo-blue-logo.png" className="loginlogo d-block mx-auto" alt='' />
              <h4>{isNotVarified  || isNotStepTwo ? null : 'Login to your account'}</h4>


              {
                isServerError && <div className="alert alert-danger alert-dismissible fade show" role="alert">
                    Invalid login credentials
                 <button type="button" className="close" onClick={()=>setServerError(false)}>
                   <span aria-hidden="true">×</span>
                 </button>
               </div>
              }
              {
                isNotStepTwo && <RegisterNextStep signupNextStep={signupNextStep}/>
              }
              {
                isNotVarified && <OtpVerification userDetails={userDetails} verifyOtp={verifyOtp} isLoading={isLoading} otpErrorResp={otpErrorResp} setOtpErrorResp={setOtpErrorResp}/>
              }
             { !isNotVarified && !isNotStepTwo ? <Formik
                initialValues={{ user_type: '', email: '', password: '' }}
                validate={values => {
                  const errors = {};
                  setServerError(false);
                  if (!values.user_type) {
                    errors.user_type = 'Please select user type.';
                  }


                  if (!values.email) {
                    errors.email = 'Username is required.';
                  } 

                  if (!values.password) {
                    errors.password = 'Password is required.';
                  }
                  return errors;
                }}

                onSubmit={submitSignIn}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  /* and other goodies */
                }) => (<form onSubmit={handleSubmit}>
                  <div className="form-group user_type">
                    <div role="group" aria-labelledby="my-radio-group" className='d-flex'>
                      <label className='mr-3 mb-0'>
                        <Field type="radio" name="user_type" value="individual" className="mr-1"/>
                        Individuals
                      </label>
                      <label className='mb-0'>
                        <Field type="radio" name="user_type" value="corporate" className="mr-1"/>
                        Corporate
                      </label>
                    </div>
                   <span className='help-error'>{errors.user_type && touched.user_type && errors.user_type}</span> 
                  </div>
                  <div className="form-group">
                    <input type="text" 
                      placeholder="Email Or Mobile Number" 
                      className="form-control" 
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    <small style={{fontSize: 10, display:'block'}}><strong>Note : </strong>If You are using phone don't enter country code.</small>
                     <span className='help-error'>{errors.email && touched.email && errors.email}</span>
                  </div>
                  <div className="form-group">
                    <input type="password" 
                    placeholder="Password" 
                    className="form-control"
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password} />
                     <span className='help-error'>{errors.password && touched.password && errors.password}</span>
                  </div>
                  <p><input type="checkbox" /> Remember Me <Link to="/forget-password" className="pull-right text-green">Forgot Password</Link></p>
                  <div className="form-group">
                    <button type="submit" className="btn btn-green btn-block">{isLoginLoader ? 'Please wait..':'LOGIN'}</button>
                    <p className="text-center">Don't have an account? <Link to="/register" className='text-green'>Register now.</Link></p>
                  </div>
                </form>
                )}
              </Formik> : null
}
            </div>
          </div>
        </div>
      </div>
    </section>

  )
}

export default Login