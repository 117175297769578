import React, { useState, useEffect } from 'react';

function CountdownTimer({setResendOtp}) {
  const [minutes, setMinutes] = useState(10);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      if (minutes === 0 && seconds === 0) {
        setResendOtp(true);
        clearInterval(timerInterval);
        // Timer is done, handle the countdown completion here.
      } else {
        if (seconds === 0) {
          setMinutes(minutes - 1);
          setSeconds(59);
        } else {
          setSeconds(seconds - 1);
        }
      }
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [minutes, seconds]);

  return (
    <div>
      <div style={{fontSize:12, color:'red'}}>
       Expire in  {minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}
      </div>
    </div>
  );
}

export default CountdownTimer;
