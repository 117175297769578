import React, { Component, useRef } from 'react'
import { GetHome } from '../../helpers';
import Suggestions from './Suggestions';
class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      results: []
    }
    this.sgRef = React.createRef();
  }
  getInfo = async () => {
    const data = {
      search: this.state.query,
      Scope: "price_high_to_low"
    }
    const res = await GetHome.productSearch(data);
    console.log(res.data);
    if (res && res.status === 200) {
      this.setState({
        results: res.data
      })
    } else {
      this.setState({
        results: []
      })
    }
  }
  handleInputChange = () => {
    this.setState({
      query: this.search.value
    }, () => {
      if (this.state.query && this.state.query.length > 1) {
        if (this.state.query.length % 2 === 0) {
          this.getInfo()
        }
      } else {
        this.setState({
          results: []
        })
      }
    })
  }
  resetSearch = () => {
    this.setState({
      query: '',
      results: []
    })
  }
  componentDidMount(){
    let handler = (event)=>{
      if(event.target){
        if(this.sgRef.current !== null && !this.sgRef.current.contains(event.target)){
          this.setState({
           results:[]
          })
         }
      }
     
    }
    document.addEventListener("mousedown", handler);
    return () =>{
      document.removeEventListener("mousedown", handler);
    }
  }
  render() {
    return (
      <>
      <div className='search-wrapper'>
      <i className="fa fa-search"/>
      <input type="text" className="form-control searching" placeholder="Search for product" ref={input => this.search = input}
          onChange={this.handleInputChange} />
      </div>
        {
          this.state.results.length !== 0 && <Suggestions sgRef={this.sgRef} results={this.state.results} resetSearch={this.resetSearch} />
        }

      </>
    )
  }
}

export default SearchBar;